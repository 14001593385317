import Typography from '@mui/material/Typography';
import ProjectTaskList from '../components/ProjectTaskList';
import * as Media from '../media/Media';
const projekt = {
  title: 'Ablösungsprojekt einer Dispositionssoftware für den Zugbetrieb',
  startDate: new Date('2016-06-01'),
  endDate: null,
  branche: 'Eisenbahnbetrieb (Infrastruktur)',
  image: Media.IMG_SCHIENE,
  imageText: 'text',
  description: (
    <Typography>
      Zur Ablösung der auf acht Betriebszentralen verteilten dezentralen
      Altsysteme zur Disposition wurde eine Dispositionssoftware samt Quellcode
      von einem in der Schweiz ansässigen Schieneninfrastrukturbetreiber gekauft
      und wird seitdem an die Bedürfnisse des Kunden angepasst. Neben dem im
      Vergleich zur Schweiz erhöhtem (vielfachem) Datenaufkommen sind zusätzlich
      verschiedenste Anforderungen umzusetzen, da sich die Disposition von Zügen
      in Deutschland und der Schweiz sich in vielen Dingen unterscheidet.
      Herausfordernd ist hierbei vor allem die Konsolidierung der
      unterschiedlichen Arbeitsweisen und Prozesse der acht Betriebszentralen
      und den daraus resultierenden Anforderungen im komplexen Projektumfeld zu
      managen. Zusätzliche Herausforderungen ergeben sich darin, dass das 
      Projekt eingebettet ist in einem Programm mit mehr als 11 Teilprojekten.
    </Typography>
  ),
  role: (
    <Typography>
      SAFe Solution Architect/Engineer (SAFe SAE),
      Softwareentwicklung,
      Architektur,
      ScrumMaster,
      stellvertretende technische Projektleitung
    </Typography>
  ),
  customer: (
    <Typography>
      großer deutscher Schieneninfrastrukturbetreiber (Eisenbahnbetrieb)
    </Typography>
  ),
  place: <Typography>Frankfurt a.M.</Typography>,
  teamsize: <Typography>80</Typography>,
  tasks: (
    <div>
      <ProjectTaskList
        title='Umsetzung mit Java 8 &amp; 11'
        items={[
          'Einsatz von Spring Boot, Eclipse RCP, Tibco RV, Tibco EMS, Oracle DB, Oracle Coherence, gRPC, Protobuf',
          'Grundlegende Beeinflussung und führende Rolle im Bereich der Softwarearchitektur',
          'Hauptansprechpartner beim Design von anspruchsvollen Lösungen',
          'Grundlegende Beeinflussung und führende Rolle im Bereich der Softwarearchitektur',
          'Lead-Developer im Projekt',
          'Einsatz von Git zur Verwaltung des Quellcodes Anforderungsmanagement',
        ]}
      />
      <ProjectTaskList
        title='Agile Methoden und Praktiken sowie Teamleitung und Projektleitung'
        items={[
          'SAFe Solution Architect/Engineer (SAFe SAE – seit 2021)',
          'Scrum Master verschiedener Scrum-Teams (2016 - 2021)',
          'Agile Leader im Projektumfeld (organisiert nach SAFe)',
          'Schätzung von Anforderungen außerhalb der Scrum- und SAFe-Regelmeetings',
          'Keyplayer im Projekt im Bereich Agilität und Teamführung',
          'Anleitung von neuen Teammitgliedern, die ins Projekt einsteigen',
          'Stellvertretende technische Projektleitung (2016-2018)',
        ]}
      />
      <ProjectTaskList
        title='Anforderungsmanagement'
        items={[
          'Interaktion mit dem Kunden und Stakeholdern zur Vorbereitung, Klärung und Konsolidierung der benötigten Anforderungen',
          'Konsolidierung von Anforderungen der acht verschiedenen Betriebszentralen',
          'Nutzung von Atlassian Jira zur Erfassung und Verwaltung von Anforderungen',
        ]}
      />
      <ProjectTaskList
        title='Sonstige Themen und Tooleinsatz'
        items={[
          'Einsatz von HP ALM / Qualitycenter zur Verwaltung von Testfällen und Defects',
          'Einsatz von Enterprise Architect zur Erfassung der übergreifenden Architektur',
          'Betreuung des Pilotbetriebs in verschiedenen Betriebszentralen',
        ]}
      />
      <ProjectTaskList
        title='Kundenmanagement'
        items={[
          'Key-Account-Management der mtrail Deutschland GmbH gegenüber der Deutschen Bahn AG',
        ]}
      />
    </div>
  ),
};

export default projekt;
